var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"v-sign",class:{'width_335':_vm.width==335}},[(_vm.showBeforeSign)?_c('div',{staticClass:"before-btn",style:({
      'width': _vm.width + 'px',
      'height': _vm.height + 'px'
    })},[_c('v-button',{attrs:{"type":"cobalt","iconLeft":"icon-Edit"},on:{"click":function($event){_vm.showBeforeSign = false}}},[_vm._v("簽署 Signature")])],1):_vm._e(),_c('div',{staticClass:"sign-box",class:{
      'sign-border': !_vm.showBeforeSign
    },style:({
      'width': _vm.width + 'px',
      'height': _vm.height + 'px'
    })},[_c('div',{staticClass:"icon-list"},[_c('span',{staticClass:"icon-box",on:{"click":_vm.handlerReset}},[_c('i',{staticClass:"el-icon-close close"})])]),_c('span',{staticClass:"sign-tips"},[_vm._v(" 請於此處簽署 Please sign here ")]),_c('vue-esign',{ref:"esign",attrs:{"width":_vm.width,"height":_vm.height,"isCrop":_vm.isCrop,"lineWidth":_vm.lineWidth,"lineColor":_vm.lineColor,"bgColor":_vm.bgColor}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }